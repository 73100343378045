import { useEffect } from "react";
import { MainWallOfLoveComponent } from "./main.wol";
import { useWallOfLoveStore } from "@store";


export function WallOfLovePagePreviewPage() {

    useEffect(() => {

        window.addEventListener("message", handleMessages);

        return () => window.removeEventListener("message", handleMessages);

    }, []);

    const handleMessages = (e: MessageEvent<Messages.Types>) => {

        if (e.data.type === "wallOfLoveSettings") {

            console.log(e.data.payload);

            useWallOfLoveStore.getState().setSettings(e.data.payload);

        } else if (e.data.type === "wallOfLoveTestimonials") {

            useWallOfLoveStore.getState().setTestimonials(e.data.payload);

        }

    };

    return (
        <div className="w-screen h-screen">

            <MainWallOfLoveComponent />

        </div>
    );
}