import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";
import { StoreKeys } from "../keys";


const State: Testimonial.State = {
    landedAt: "",
    id: "",
    metaData: {
        country: null,
        ip: null,
        origin: null,
        userAgent: null
    },
    outcome: "",
    startedAt: "",
    submittedAt: "",
    type: "",
    hiddenFields: {},
    customerDetails: {},
    customConsentAccepted: true,
    rating: 0,
    durationInMS: 0,
    format: "",
    sizeInBytes: 0,
    imageAttachments: null,
    textTestimonialContent: "",
    rewardSpun: false,
    rewardWinnedCoupon: "",
    rewardVisited: false,
};


export const useTestimonialStore = create<Testimonial.Type>()(devtools(persist(immer((set => ({
    ...State,
    set: (params) => set((state) => {

        return { ...state, ...params };

    },
        false,
        "testimonial/set"
    ),
    setId: (id) => set((state) => {

        state.id = id;

    },
        false,
        "testimonial/setId"
    ),
    setCustomDetails: (params) => set((state) => {

        state.customerDetails[params.id] = {
            answer: params.answer as any,
            type: params.type as any,
            varType: params.varType as any
        };

    },
        false,
        "testimonial/setCustomDetails"
    ),
    setType: (type) => set((state) => { state.type = type; },
        false,
        "testimonial/setType"
    ),
    setHiddenFields: (fields) => set((state) => { state.hiddenFields = fields; },
        false,
        "testimonial/setHiddenFields"
    ),
    setMetaData: (params) => set((state) => { state.metaData = { ...state.metaData, ...params }; },
        false,
        "testimonial/setMetaData"
    ),
    setOutcome: (outcome) => set((state) => { state.outcome = outcome; },
        false,
        "testimonial/setOutcome"
    ),
    setReward: (reward) => set((state) => {

        state.rewardSpun = reward.rewardSpun ?? false;
        state.rewardWinnedCoupon = reward.rewardWinnedCoupon ?? "";
        state.rewardVisited = reward.rewardVisited ?? false;

    },
        false,
        "testimonial/setReward"
    ),
    setStartedAt: (startedAt) => set((state) => { state.startedAt = startedAt; },
        false,
        "testimonial/setStartedAt"
    ),
    setCustomConsent: (consent) => set((state) => { state.customConsentAccepted = consent; },
        false,
        "testimonial/setCustomConsent"
    ),
    setSubmittedAt: (submittedAt) => set((state) => { state.submittedAt = submittedAt; },
        false,
        "testimonial/setSubmittedAt"
    ),
    setLandedAt: (landedAt) => set((state) => { state.landedAt = landedAt; },
        false,
        "testimonial/setLandedAt"
    ),
    /* testimonial */
    setAudioTestimonial: (param) => set((state) => {

        state.durationInMS = param?.durationInMS ?? 0;
        state.format = param?.format ?? "";
        state.sizeInBytes = param?.sizeInBytes ?? 0;
        state.imageAttachments = param?.imageAttachments ?? [];

    },
        false,
        "testimonial/setAudioTestimonial"
    ),
    setVideoTestimonial: (params) => set((state) => {

        state.durationInMS = params?.durationInMS ?? 0;
        state.format = params?.format ?? "";
        state.sizeInBytes = params?.sizeInBytes ?? 0;

    },
        false,
        "testimonial/setVideoTestimonial"
    ),
    setTextTestimonial: ({ imageAttachments, textTestimonialContent }) => set((state) => {

        state.textTestimonialContent = textTestimonialContent ?? "";
        state.imageAttachments = imageAttachments ?? null;

    },
        false,
        "testimonial/setTextTestimonialContent"
    ),
    setRating: (rating) => set((state) => {

        state.rating = rating;

    },
        false,
        "testimonial/setRating"
    ),
    clear: () => set((state) => {

        state.id = "";
        state.customConsentAccepted = false;
        state.customerDetails = {};
        state.hiddenFields = {};
        state.landedAt = "";
        state.metaData = {
            country: "",
            ip: "",
            origin: "",
            userAgent: ""
        };
        state.outcome = "";
        state.startedAt = "";
        state.submittedAt = "";
        state.type = "text";
        state.rewardSpun = false;
        state.rewardWinnedCoupon = null;
        state.rewardVisited = null;
        state.textTestimonialContent = "";
        state.imageAttachments = null;

    },
        false,
        "useTestimonialStore/clear"
    )
}))), {
    name: StoreKeys.testimonial
}), {
    name: StoreKeys.testimonial,
    enabled: import.meta.env.DEV
}));