import { useWallOfLoveStore } from '@store';
import Masonry from 'react-masonry-css';
import { TextTestimonialCard } from './text';
import { VideoTestimonialCard } from './video';
import { cn } from '@utils/cn';
import { useLocation } from 'react-router-dom';

export function TestimonialSection() {

    const location = useLocation();

    const isPreview = location.pathname.includes("preview");

    const { columnCount, testimonials } = useWallOfLoveStore((store) => ({
        columnCount: store.content.columnCount,
        testimonials: store.testimonials
    }));

    const onGetColumnCount = () => {

        if (window.innerWidth <= 500) return 1;

        if (window.innerWidth <= 800) return 2;

        return columnCount;

    };

    return (
        <div className="w-full m-auto translate-y-[-60px] flex items-center justify-center">

            <Masonry
                className={cn(`my-masonry-grid bg-transparent w-full transition-all duration-300 ease-in-out`, {
                    "w-[90%]": isPreview,
                    "w-[80%]": isPreview === false
                })}
                columnClassName="my-masonry-grid_column"
                breakpointCols={{
                    default: columnCount,
                    700: 2,
                    500: 1
                }}
            >

                {testimonials.map((testimonial, idx) => (
                    <div
                        key={idx}
                        className="w-auto relative h-auto"
                        style={{
                            width: isPreview ? `${((window.innerWidth) / onGetColumnCount()) - 40}px` : `${((window.innerWidth - 100) / onGetColumnCount()) - 40}px`
                        }}
                    >

                        {testimonial.type === "video" ?
                            <VideoTestimonialCard {...testimonial} /> :
                            <TextTestimonialCard {...testimonial} />
                        }

                    </div>
                ))}

            </Masonry >

        </div>
    );
}