import { Button } from "@components/button";
import { useCommonStore, useFormStore, useTestimonialStore, useUtilStore } from "@store";
import { LuPencil } from "react-icons/lu";
import { ImageAttachments } from "./imageAttachment";
import { useState } from "react";
import { useIsPreview, useTestimonialApiClient } from "@hooks";


export function TextTestimonial() {

    const isPreview = useIsPreview();

    const { primaryColor, collectAttachments, fontFamily } = useFormStore((store) => ({
        primaryColor: store.design.primaryColor,
        collectAttachments: store.response.text.collectAttachments,
        fontFamily: store.design.fontFamily
    }));

    const { content, attachments } = useTestimonialStore((store) => ({
        content: store.textTestimonialContent ?? "",
        attachments: store.imageAttachments ?? []
    }));

    const files = useUtilStore((store) => store.imageAttachments);

    const [error, setError] = useState("");
    const [uploading, setUploading] = useState(false);
    // const [progress, setProgress] = useState(0);
    // const [allProgress, setAllProgress] = useState<{ [key: string]: number; }>({});

    // useEffect(() => {

    //     const total = Object.values(allProgress).reduce((a, b) => a + b, 0);

    //     setProgress(Math.floor(total / Object.keys(allProgress).length));

    // }, [JSON.stringify(allProgress)]);

    const { getUploadUrl, submit } = useTestimonialApiClient({});

    const onSubmit = async () => {

        if (!content?.trim().length || !content) {
            setError("Testimonial cannot be empty.");
            return;
        }

        setUploading(true);

        const parsedFiles: any[] = [];
        // const attachmentUrls: string[] = [];

        Object.keys(files).forEach(fileId => {

            if (files[fileId]) {
                parsedFiles.push({
                    contentLength: files[fileId]?.size,
                    key: fileId,
                    type: files[fileId]?.type
                });
            }
        });

        // if (parsedFiles.length) {

        // const data = await getUploadUrl.mutateAsync({
        //     formId,
        //     responseId,
        //     source: "testimonialAttachments",
        //     files: [...parsedFiles]
        // });

        // const promiseArr = data.urls.map((item) => {

        //     const file = files[item.key];

        //     attachmentUrls.push(`${formId}/${responseId}/${item.key}`);

        //     return uploadFile({
        //         url: item.url,
        //         contentType: item.contentType,
        //         fields: item.fields,
        //         file: file as File,
        //         key: item.key
        //     }, (progress) => setAllProgress({ [item.key]: progress }));

        // });

        // await Promise.all(promiseArr);

        // }

        // await submit.mutateAsync({
        //     formId,
        //     id: responseId,
        //     source: "testimonial",
        //     event: {
        //         landedAt: useTestimonialStore.getState().landedAt,
        //         startedAt: useTestimonialStore.getState().startedAt,
        //         metaData: useTestimonialStore.getState().metaData,
        //         type: "text",
        //         testimonial: {
        //             text: {
        //                 content,
        //                 imageAttachments: [...attachmentUrls]
        //             },
        //             rating
        //         }
        //     }
        // });

        useCommonStore.getState().setCurrentStep("customDetails");

        setUploading(false);

    };

    return (
        <div>

            <textarea
                rows={10}
                disabled={isPreview}
                value={content ?? ""}
                placeholder="Write something nice ✨"
                className={"border-2 border-blue-600 rounded-md w-full p-3 text-base font-normal outline-none"}
                onChange={(e) => useTestimonialStore.getState().setTextTestimonial({
                    textTestimonialContent: e.target.value,
                    imageAttachments: attachments,
                })}
                style={{
                    borderColor: error ? "red" : primaryColor,
                    fontFamily
                }}
            />

            {error ?
                <span
                    className='text-red-500 flex flex-row items-center space-x-1 px-1 text-sm font-normal text-left'
                    style={{
                        fontFamily
                    }}
                >
                    * {error}
                </span > : null
            }

            {collectAttachments ? <ImageAttachments /> : null}

            {/* {attachments.length ?
                <p style={{ fontFamily }}>
                    uploaded - {progress}%
                </p> : null
            } */}

            <Button
                disabled={submit.isPending || getUploadUrl.isPending || isPreview || uploading}
                loading={submit.isPending || getUploadUrl.isPending || uploading}
                className="space-x-2"
                onClick={() => onSubmit()}
                style={{ fontFamily }}
            >

                <LuPencil className="w-4 h-4" />

                <p className="mx-1"> Submit </p>

            </Button>

        </div>
    );
}