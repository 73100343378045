import { create } from "zustand";
import { State } from "./state";
import { devtools } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";

export const useWallOfLoveStore = create<WallOfLoveStore.Type>()(devtools(immer(set => ({
    ...State,
    setSettings: (params) => set((state) => {

        state.branding = { ...state.branding, ...params.branding };
        state.content = { ...state.content, ...params.content };
        state.hero = { ...state.hero, ...params.hero };
        state.navigation = { ...state.navigation, ...params.navigation };
    },
        false,
        "wallOfLove/setSettings"
    ),
    setTestimonials: (testimonials) => set((state) => {
        state.testimonials = testimonials;
    },
        false,
        "wallOfLove/setTestimonials"
    ),
}))));