import { Wol_Template1_TopSection } from "./t1/top";
import { TestimonialSection } from "./t1/testimonials/main.testimonial";
import { useLocation } from "react-router-dom";



export function MainWallOfLoveComponent() {

    const { pathname } = useLocation();

    const isPreview = pathname.includes("preview");

    return (
        <div className="w-full h-full max-w-full overflow-y-scroll bg-gray-100"
            style={{
                zoom: isPreview ? "0.9" : undefined
            }}
        >

            <Wol_Template1_TopSection />

            <TestimonialSection />

        </div >
    );
}