import { useGradientBackground, useTestimonialApiClient } from "@hooks";
import { cn } from "@utils/cn";
import { Navigate, useParams, useSearchParams } from "react-router-dom";
import { Fragment, useEffect, } from "react";
import { Loading } from "@components/loading";
import { TestimonialLayout } from "./layout/main.layout";
import { useFormStore } from "@store";



export function TestimonialPage() {

    const [searchParams] = useSearchParams();

    const { tid, fid } = useParams() as { fid: string, tid: string; };

    const { get } = useTestimonialApiClient({});

    const isEmbed = (searchParams.get("embed") ?? "false") == "true";

    useGradientBackground();

    useEffect(() => {

        if (get.isPending === false) {

            if (get?.error) {
                console.log(get.error);
                return;
            }

            if (get?.data) {

                const form = useFormStore.getState();

                useFormStore.getState().setForm({ ...form, design: { ...get.data.form.design } });

            }

        }

    }, [get.isPending]);

    if (!fid || !tid) return <Navigate to={"/"} />;

    if (isEmbed === true) {
        return (
            <Fragment>

                {get.isPending ?
                    <Loading text="Gathering the love letter... Hold tight!" /> :
                    <TestimonialLayout isEmbed={isEmbed} />
                }

            </Fragment>
        );
    }

    return (
        <div
            className={cn(`
                w-screen h-screen overflow-y-auto overflow-x-hidden bg-gray-100 
                relative dotted-bg 
                flex items-center justify-center 
            `)}
        >

            {get.isPending ?
                <Loading text="Gathering the love letter... Hold tight!" /> :
                <TestimonialLayout isEmbed={isEmbed} />
            }

        </div>
    );
}