import { useWallOfLoveStore } from "@store";
import { HeroCTA } from "./cta";
import { NavBar } from "./navbar";
import { cn } from "@utils/cn";

export function Wol_Template1_TopSection() {

    const { primaryColor } = useWallOfLoveStore((store) => ({
        primaryColor: store.branding.primaryColor
    }));

    return (
        <div
            className={cn(`w-full h-[60%]`)}
            style={{
                backgroundColor: primaryColor
            }}
        >

            <NavBar />

            <HeroCTA />

        </div>
    );
}