import { useCommonStore, useFormStore, useTestimonialStore } from "@store";
import { CollectTestimonial } from "./collect/collect.main";
import { CustomDetails } from "./customerDetails/customerDetails.main";
import { ThankYou } from "./thankYou/thankYou.main";
import { FormCardLayout } from "./form.layout";
import { BrandingCard, PoweredBy } from "@components/branding";
import { Welcome } from "./welcome/welcome.main";
import { useAnalyticsApiClient, useFormApiClient, useGradientBackground, useTestimonialApiClient } from "@hooks";
import { Fragment, useEffect, useState } from "react";
import WelcomeRewardCard from "./welcome/rewardCard";
import Reward from "./reward/reward.page";
import { useParams } from "react-router-dom";
import { Loading } from "@components/loading";
import { FullLogo } from "@components/icons";

export function Form() {

    const { id } = useParams() as { id: string; };

    const { currentStep } = useCommonStore();

    const { removeBranding, showReward, showGradientBg } = useFormStore((store) => ({
        removeBranding: store.settings.removeBranding,
        showReward: store.reward.allow,
        showGradientBg: store.design.background.gradient ? true : false
    }));

    const { getMetadata } = useTestimonialApiClient({});

    const { update } = useAnalyticsApiClient();

    const { get } = useFormApiClient({ formId: id });

    const [formClosed, setFormClosed] = useState(false);

    const [loading, setLoading] = useState(true);

    useGradientBackground();

    useEffect(() => {

        get.refetch();

        getMetadata.refetch();

    }, []);

    useEffect(() => {

        setTimeout(() => setLoading(false), 1000);

        if (get.isLoading === false && get.isFetching === false) {

            if (get?.error) {

                if (get?.error?.message === "formClosed") {
                    setFormClosed(true);
                    return;
                }

                return;
            }

            if (get?.data) {

                useFormStore.getState().setForm({ ...get.data.form, id });

                useTestimonialStore.getState().setId(get.data.tid);

                setTimeout(() => { update.mutate({ id, event: "view" }); }, 3000);

                useTestimonialStore.getState().setLandedAt(new Date().toISOString());

                onSetupHiddenFields();

            }

        }

    }, [get.isLoading, get.isFetching]);

    useEffect(() => {

        if (getMetadata.isLoading === false && getMetadata.isFetching === false && getMetadata.data) {

            const data = getMetadata.data;

            useTestimonialStore.getState().setMetaData({
                country: data.country,
                ip: data.ip,
                origin: window.location.origin,
                userAgent: window.navigator.userAgent
            });

        }

    }, [getMetadata.isLoading, getMetadata.isFetching]);

    const onSetupHiddenFields = () => {

        const rawHiddenFields = location.hash.split("#");

        const hiddenFields: { [key: string]: string; } = {};

        rawHiddenFields.forEach(param => {

            const [key, value] = param.split("=");

            if (key && value !== "xxxxx") hiddenFields[key] = decodeURIComponent(value);

        });

        useTestimonialStore.getState().setHiddenFields(hiddenFields);

    };

    if (formClosed) {
        return (
            <div className="w-screen h-screen flex items-center justify-center">

                This Form Is Currently Closed...

            </div>
        );
    }

    return (
        <Fragment>

            {loading ?
                <div className="w-full h-full flex flex-col items-center justify-center space-y-3">

                    <div>
                        <Loading text="" />
                    </div>

                    <div className="">
                        <FullLogo />
                    </div>

                </div> :
                <Fragment>

                    {showGradientBg ?
                        <canvas
                            id="gradient-canvas"
                            className="absolute top-0 left-0 right-0 bottom-0 w-screen h-screen"
                        /> : null
                    }

                    {currentStep !== "reward" ?
                        <FormCardLayout>

                            {currentStep === "welcome" ? <Welcome /> : null}

                            {currentStep === "collect" ? <CollectTestimonial /> : null}

                            {currentStep === "customDetails" ? <CustomDetails /> : null}

                            {currentStep === "thankyou" ? <ThankYou /> : null}

                        </FormCardLayout> :
                        <Reward />
                    }

                    {currentStep === "welcome" && showReward ? <WelcomeRewardCard /> : null}

                    {removeBranding === false && currentStep === "thankyou" ? <BrandingCard /> : null}

                    {removeBranding ? <PoweredBy /> : null}

                </Fragment>
            }

        </Fragment>
    );
}