import { ParseAxiosError } from "@lib/axios";
import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { QueryKeys } from "./keys";

type Params = {
    getCaseStudy?: {
        apiParams: {
            id: string;
        };
    };
};

export function useTestimonialApiClient(params: Params) {

    const get = useMutation({
        mutationKey: [QueryKeys.testimonial.get],
        mutationFn: async ({ fid, tid }: { fid: string, tid: string; }) => {
            try {

                return (await axios.get<Api.TestimonialClient.get>("/api/v1/testimonials", {
                    params: { fid, tid }
                })).data;

            } catch (err: any) {
                throw ParseAxiosError(err);
            }
        }
    });


    const getMetadata = useQuery({
        enabled: false,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        retry: 1,
        queryKey: ["get-meta-data"],
        queryFn: async () => {
            try {

                const res = await fetch("https://gz-request-info.team-739.workers.dev", { method: "GET" });

                const data = await res.json();

                return data as Api.TestimonialClient.getMetaData;

            } catch (err) {
                throw err;
            }
        }
    });

    const submit = useMutation({
        mutationKey: [QueryKeys.testimonial.submit],
        mutationFn: async (params: Api.TestimonialClient.submitParams) => {
            try {

                return await axios.post("/api/v1/testimonials", { ...params });

            } catch (err: any) {
                throw ParseAxiosError(err);
            }
        }
    });

    const getUploadUrl = useMutation({
        mutationKey: [QueryKeys.testimonial.getUploadUrl],
        mutationFn: async (params: Api.TestimonialClient.getUploadUrlParams) => {
            try {

                return (await axios.post<Api.TestimonialClient.getUploadUrl>("/api/v1/testimonials/upload", {
                    formId: params.formId,
                    responseId: params.responseId,
                    source: params.source,
                    files: params.files
                })).data;

            } catch (err: any) {
                throw ParseAxiosError(err);
            }
        }
    });

    const uploadFile = async (
        params: Api.TestimonialClient.getUploadUrl["urls"][0] & { file: Blob; },
        onUploadProgress?: (progress: number) => void
    ) => {
        try {

            const formData = new FormData();

            Object.keys(params.fields).forEach(field => formData.set(field, (params.fields as any)[field]));

            formData.set("Content-Type", params.contentType);

            formData.set("file", params.file);

            const unAxios = axios.create();

            const res = await unAxios.post(params.url, formData, {
                onUploadProgress: (progress) => {

                    if (progress && progress.total && progress.upload) {

                        onUploadProgress?.(Math.round((progress.loaded * 100) / progress.total));

                    }

                }
            });

            if (res.status === 200) throw new Error(res.data);

            return;

        } catch (err) {
            console.log({ err });
            throw err;
        }
    };

    const getCaseStudyQuery = useQuery({
        enabled: false,
        queryKey: [QueryKeys.testimonial.caseStudy.get],
        queryFn: async () => {
            try {

                return (await axios.get<Api.TestimonialClient.getCaseStudy>("/api/v1/testimonials/case-study", {
                    params: {
                        tid: params.getCaseStudy?.apiParams.id
                    }
                })).data;

            } catch (err: any) {
                throw ParseAxiosError(err);
            }
        }
    });

    return {
        get,
        getMetadata,
        submit,
        getUploadUrl,
        uploadFile,
        getCaseStudyQuery
    };

}