import { CaseStudyPage } from "@pages/caseStudy/main.page";
import { FormEmbedPage } from "@pages/form/embed.page";
import { FormPage } from "@pages/form/form.page";
import { FormPreviewPage } from "@pages/form/preview.page";
import { TestimonialPage } from "@pages/testimonial/testimonial.page";
import { WallOfLovePagePreviewPage } from "@pages/wallOfLove/preview.page";
import { WallOfLovePage } from "@pages/wallOfLove/wallOfLove.page";
import { Route, Routes } from "react-router-dom";


export function App() {

    return (
        <Routes>

            <Route path="/" element={"redirect to landing page | app"} />

            {/* form pages */}
            <Route path="/form/:id" element={<FormPage />} />

            <Route path="/form/preview" element={<FormPreviewPage />} />

            {/* embed */}
            <Route path="/form/embed/:id" element={<FormEmbedPage />} />

            {/* single testimonial page */}
            <Route path="/testimonial/:fid/:tid" element={<TestimonialPage />} />

            {/* case study */}
            <Route path="/case-study/:id" element={<CaseStudyPage />} />

            {/* wall of love */}
            <Route path="/wall-of-love/:id" element={<WallOfLovePage />} />

            <Route path="/wall-of-love/preview/:id" element={<WallOfLovePagePreviewPage />} />

            {/* error page */}
            <Route path="/*" element={"Page not found..."} />

        </Routes>
    );
}