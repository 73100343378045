import { Config } from "@config";
import { useWallOfLoveStore } from "@store";
import { cn } from "@utils/cn";

export function NavBar() {

    const { branding, navigation } = useWallOfLoveStore((store) => ({
        branding: store.branding,
        navigation: store.navigation,
    }));

    const { logo, logoSize } = branding;

    const { headerLinks, centerNav } = navigation;

    return (
        <div
            className={cn(`w-full h-[25%] flex items-center justify-between py-4 px-6`)}
        >

            <div className="w-auto bg-white rounded-lg z-[10]">

                <img
                    src={logo ? logo : Config.misc.placeHolderLogo}
                    className="w-[50px] h-[50px] p-1 shadow-lg"
                    style={{
                        height: `${logoSize}px`,
                        width: `${logoSize}px`,
                    }}
                />

            </div>

            {headerLinks.length ?
                <div
                    className={cn(`w-full flex items-center justify-end gap-5`, {
                        "justify-center": centerNav
                    })}
                >

                    {headerLinks.map((item, idx) => (
                        <a
                            key={idx}
                            href={item.url}
                            target={item.openInNewTab ? "_blank" : "_self"}
                            className="hover:underline text-base font-medium"
                            style={{
                                color: branding.fontColor
                            }}
                        >
                            {item.title}
                        </a>
                    ))}

                </div> : null
            }

        </div>
    );
}