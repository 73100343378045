
export const State: WallOfLoveStore.State = {
    branding: {
        logo: "",
        logoSize: 50,
        primaryColor: "#2563EB",
        fontFamily: "Inter",
        removeBranding: false,
        fontColor: "white",
        buttonColor: "white",
    },
    content: {
        showDates: true,
        columnCount: 3,
        inlineCTA: null,
        showRatings: true,
        showCustomerPhoto: true,
        showGravatarPhotoIfNotAvailable: false,
    },
    hero: {
        title: "Wall of Love",
        subTitle: "See what customers are saying about us",
        cta: null,
    },
    navigation: {
        hideNavBar: false,
        homeLink: "",
        centerNav: false,
        headerLinks: []
    },
    testimonials: []
};