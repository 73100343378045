import { ParseAxiosError } from "@lib/axios";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { QueryKeys } from "./keys";

export function useWallOfLoveApiClient() {

    const get = useMutation({
        mutationKey: [QueryKeys.wol.get],
        mutationFn: async (id: string) => {
            try {

                return (await axios.get<Api.WallOfLove.Get>("/api/v1/wall-of-love/r", { params: { id } })).data;

            } catch (err: any) {
                throw ParseAxiosError(err);
            }
        }
    });

    return {
        get
    };

}