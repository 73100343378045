import { Rating } from "@components/rating";
import { TestimonialSource } from "@components/testimonialSources";
import { useWallOfLoveStore } from "@store";
import { cn } from "@utils/cn";
import { useEffect, useRef, useState } from "react";
import ReactHlsPlayer from "react-hls-player";
import { FaPause, FaPlay } from "react-icons/fa6";
import { useLocation } from "react-router-dom";

type Props = Api.WallOfLove.Get["testimonials"][0];

export function VideoTestimonialCard({ id, customerDetails, videoTestimonialThumbnail, source, url, rating }: Props) {

    const { pathname } = useLocation();

    const { showRatings, } = useWallOfLoveStore((store) => store.content);

    const playerRef = useRef<any>(null);

    const [play, setPlay] = useState(false);

    const isImported = true;

    const videoUrl = url ?? "";

    const isPreview = pathname.includes("preview");

    useEffect(() => {

        if (playerRef.current) {
            playerRef.current.play();
        }

    }, []);

    const onPlay = () => {

        if (play) {
            playerRef.current?.pause();
            setPlay(false);
        } else {
            playerRef.current?.play();
            setPlay(true);
        }

    };

    return (
        <div
            id={id}
            className={cn("w-full h-auto rounded-xl bg-white border")}
        >

            <div className="relative">

                {source === "youtube" && videoTestimonialThumbnail && url ?
                    <div
                        onClick={() => window.open("_blank", url)}
                    >

                        <img
                            src={videoTestimonialThumbnail}
                            className={cn("w-full h-[270px] rounded-xl", {
                                "h-[230px]": isPreview
                            })}
                        />

                        <TestimonialSource
                            source={source}
                            sourceLink={""}
                            className="w-8 h-8 absolute top-5 right-5"
                        />


                    </div> :
                    <ReactHlsPlayer
                        playerRef={playerRef}
                        src={videoUrl}
                        autoPlay={false}
                        className={cn("outline-none border border-grey-500 ", {
                            "rounded-t-xl": isImported,
                            "rounded-xl": !isImported

                        })}
                        onEnded={() => setPlay(false)}
                    />
                }


                <div className="absolute bottom-4 left-0 flex items-center justify-between w-full px-4">

                    <div className="space-y-2">

                        {showRatings && rating ?
                            <Rating
                                rated={rating}
                                readonly
                                iconProps={{
                                    className: "w-4 h-4"
                                }}
                            /> : null
                        }

                        <div className="space-y-1">

                            <h2 className="text-lg leading-5 font-medium tracking-wide text-white cursor-default">
                                {customerDetails?.name?.answer ?? "John"}
                            </h2>

                            <div className="text-xs font-normal tracking-wide cursor-default text-white">

                                {customerDetails?.companyUrl && customerDetails.role && customerDetails.company ?
                                    <a
                                        rel="nofollow"
                                        href={customerDetails?.companyUrl?.answer ?? ""}
                                    >

                                        {customerDetails?.role?.answer ?? ""} of {customerDetails?.company?.answer ?? ""}

                                    </a> : null
                                }

                                {customerDetails.role && customerDetails.company ?
                                    <>
                                        {customerDetails?.role?.answer ?? ""} of {customerDetails?.company?.answer ?? ""}
                                    </> : null
                                }

                            </div>

                        </div>

                    </div>

                    <div
                        className={cn(`
                            p-1.5 hover:bg-gray-300/40
                            transition-all duration-300 cursor-pointer rounded-md
                        `)}
                        onClick={() => onPlay()}
                    >

                        {play ?
                            <FaPause className="w-6 h-6 text-white" /> :
                            <FaPlay className="w-6 h-6 text-white" />
                        }

                    </div>

                </div>

            </div>

        </div>
    );
}