import { useWallOfLoveStore } from "@store";
import { cn } from "@utils/cn";


export function HeroCTA() {

    const { branding, hero } = useWallOfLoveStore((store) => ({
        branding: store.branding,
        hero: store.hero
    }));

    const { title, subTitle, cta } = hero;

    return (
        <div className="w-full h-[75%]">

            <div
                className="h-[80%] w-full flex flex-col items-center justify-center"
            >

                <p
                    className={cn(`text-6xl font-semibold tracking-wide text-white`)}
                    style={{
                        color: branding.fontColor
                    }}
                >
                    {title}
                </p>

                <p
                    className="text-base font-normal tracking-wide text-white my-2"
                    style={{
                        color: branding.fontColor
                    }}
                >
                    {subTitle}
                </p>

                {cta ?
                    <button
                        className={cn(`
                        bg-white rounded-full inline w-auto text-black px-4 py-2 text-base font-medium
                        transition-all duration-300 ease-in-out hover:bg-opacity-40 mt-4
                    `)}
                        onClick={() => window.open(cta.url, "_blank")}
                    >
                        {cta.title}
                    </button> : null
                }


            </div>


        </div>
    );
}