import { Rating } from "@components/rating";
import { TestimonialSource } from "@components/testimonialSources";
import { useWallOfLoveStore } from "@store";
import { cn } from "@utils/cn";
import { GenerateProfilePic } from "@utils/generateProfile";
import { AudioTestimonial } from "./audio";
import { url } from "inspector";

type Props = Api.WallOfLove.Get["testimonials"][0];

export function TextTestimonialCard({ id, type, source, customerDetails, url, rating, textTestimonialContent, submittedAt }: Props) {

    const { showRatings, showDates, showCustomerPhoto, } = useWallOfLoveStore((store) => store.content);

    const imgSrc = customerDetails?.profilePic?.answer ?
        customerDetails?.profilePic.answer :
        customerDetails?.name ? GenerateProfilePic(customerDetails?.name?.answer[0]) : GenerateProfilePic("A");

    return (
        <div
            id={id}
            className={cn(`
                w-auto h-auto rounded-lg p-4 shadow-md
                bg-white border-gray-300 space-y-3 relative
            `)}
            style={{
                gridRow: "span auto",
                transform: "translateX(300px) translateY(100px);"
            }}
        >

            <div className="flex items-center justify-between space-x-2">

                <div className="flex items-center space-x-2">

                    {showCustomerPhoto ?
                        <img
                            src={imgSrc}
                            className={cn(`
                            flex-shrink-0 object-cover object-center w-10 h-10 
                            border border-gray-300 rounded-full cursor-default
                            `)}
                        /> : null
                    }

                    <div>

                        <h2 className="text-base leading-5 font-medium tracking-wide text-gray-800 cursor-default">
                            {customerDetails?.name?.answer ?? "John"}
                        </h2>

                        {customerDetails?.companyUrl && customerDetails?.role ?
                            <div className="text-xs font-normal tracking-wide cursor-default text-gray-700">

                                {customerDetails?.companyUrl ?
                                    <a
                                        rel="nofollow"
                                        href={customerDetails?.companyUrl.answer ?? ""}
                                    >

                                        {customerDetails?.role.answer ?? "CEO"} of {customerDetails?.company?.answer ?? "Pied"}

                                    </a> :
                                    <>
                                        {customerDetails?.role.answer ?? "CEO"} of {customerDetails?.company?.answer ?? "Pied"}
                                    </>
                                }

                            </div> : null
                        }

                    </div>

                </div>

                {source !== "gozenTestimonial" ?
                    <TestimonialSource
                        source={source}
                        sourceLink={""}
                        className="w-8 h-8"
                    /> : null
                }

            </div>

            {showRatings && rating ?
                <Rating
                    readonly
                    rated={rating}
                /> : null
            }

            <div >

                {type === "text" && textTestimonialContent ?
                    <div>

                        <p
                            className=""
                            dangerouslySetInnerHTML={{ __html: textTestimonialContent }}
                        />

                    </div>
                    : null
                }

                {type === "audio" && url ?
                    <AudioTestimonial url={url} />
                    : null
                }

            </div>

            {showDates && submittedAt ?
                <p className="text-sm font-normal text-gray-500 mt-3">
                    {new Date(submittedAt).toLocaleString("EN", { dateStyle: "medium" })}
                </p> : null
            }

        </div>
    );
}