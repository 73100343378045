import { cn } from "@utils/cn";
import { Fragment, lazy, Suspense, useEffect, useRef, useState } from "react";
import { useCommonStore, useFormStore } from "@store";
import { AnimatePresence } from "framer-motion";
import { useFormDesign, useGradientBackground, useIsPreview } from "@hooks";
import { Loading } from "@components/loading";

const Reward = lazy(() => import("./sections/reward/reward.page"));
const SpinWheel = lazy(() => import("./sections/reward/spinWheel/wheel.main"));

const FormCardLayout = lazy(() => import('./sections/form.layout'));

const Welcome = lazy(() => import("./sections/welcome/welcome.main"));
const CollectTestimonial = lazy(() => import("./sections/collect/collect.main"));
const CustomDetails = lazy(() => import("./sections/customerDetails/customerDetails.main"));
const ThankYou = lazy(() => import("./sections/thankYou/thankYou.main"));

const WelcomeRewardSection = lazy(() => import("./sections/welcome/rewardCard"));

export function FormPreviewPage() {

    const isPreview = useIsPreview();

    const { showGradientBg } = useFormStore((store) => ({
        showGradientBg: store.design.background
    }));

    const { background } = useFormDesign();

    useGradientBackground();

    const ref = useRef<HTMLDivElement>(null);

    const [active, setActiveScreen] = useState("welcome");
    const [height, setHeight] = useState(0);

    useEffect(() => {

        window.parent.postMessage({ type: "getMinHeight" as Messages.Types["type"] }, "*");

        window.addEventListener("message", handleMessages);

        if (ref.current) {

            const scrollable = ref.current.scrollHeight > ref.current.clientHeight;

            setHeight(scrollable ? ref.current?.offsetHeight + ref.current?.scrollHeight : 0);

            window.parent.postMessage({ type: "form" as Messages.Types["type"] }, "*");

        }

        return () => window.removeEventListener("message", handleMessages);

    }, []);

    const handleMessages = (e: MessageEvent<Messages.Types>) => {

        const data = e.data;

        if (data.type === "form") {

            useFormStore.setState({ ...data.payload });

        } else if (data.type === "currentScreen" && data.payload.screen) {

            setActiveScreen(data.payload.screen);

        } else if (data.type === "sendDefaultScreenHeight" && ref.current) {

            ref.current.style.minHeight = `${data.payload.height}px`;

        } else if (data.type === "responseTestimonialType") {

            useCommonStore.getState().setPreviewTestimonialType(data.payload.type);

        } else if (data.type === "sendActiveRewardSegmentId") {

            useCommonStore.getState().setActiveRewardSegmentId(data.payload.id);

        } else return;

    };

    return (
        <div
            ref={ref}
            className={cn(`
                h-screen overflow-y-auto overflow-x-hidden bg-gray-100 relative dotted-bg
                flex flex-col items-center justify-center min-h-full
            `, {
                "overflow-hidden pt-0": isPreview
            })}
            style={{
                height: height ? `${(height)}px` : 'auto',
                background: background.color
            }}
        >

            {showGradientBg ?
                <canvas
                    id="gradient-canvas"
                    className="absolute top-0 left-0 right-0 bottom-0 w-screen h-screen"
                /> : null
            }

            <Suspense fallback={<div className="h-full w-full"> <Loading text="" /> </div>}>

                <AnimatePresence initial={false}>

                    {active === "reward-page" || active === "reward" || active === "reward-preview" || active === "reward-popup" ?
                        <Fragment>

                            {active === "reward-page" || active === "reward" ? <Reward /> : null}

                            {active === "reward-preview" || active === "reward-popup" ?
                                <SpinWheel
                                    showPopup={active === "reward-popup"}
                                /> : null
                            }

                        </Fragment> :
                        <Fragment>

                            <FormCardLayout>

                                {active === "welcome" || active === "reward-welcome" ? <Welcome /> : null}

                                {active === "response" ? <CollectTestimonial /> : null}

                                {active === "customerDetails" ? <CustomDetails /> : null}

                                {active === "thankyou" || active === "reward-cta" ? <ThankYou /> : null}

                            </FormCardLayout>

                            {active === "reward-welcome" ? <WelcomeRewardSection /> : null}

                        </Fragment>
                    }

                </AnimatePresence>

            </Suspense>

        </div >
    );
}