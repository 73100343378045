import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { MainWallOfLoveComponent } from "./main.wol";
import { useWallOfLoveApiClient } from "@hooks";
import { Loading } from "@components/loading";
import { useWallOfLoveStore } from "@store";
import { FullLogo } from "@components/icons";


export function WallOfLovePage() {

    const { id } = useParams() as { id: string; };

    const { get } = useWallOfLoveApiClient();

    const [loading, setLoading] = useState(true);

    useEffect(() => { onLoad(); }, []);

    useEffect(() => {

        setTimeout(() => {

            setLoading(false);

        }, 500);

    }, [get.isPending]);

    const onLoad = async () => {
        try {

            const data = await get.mutateAsync(id);

            useWallOfLoveStore.getState().setSettings(data.settings);
            useWallOfLoveStore.getState().setTestimonials(data.testimonials);

        } catch (err) {

        }
    };

    return (
        <div className="w-screen h-screen">

            {loading ?
                <div className="w-full h-full flex flex-col items-center justify-center space-y-3">

                    <div>
                        <Loading text="" />
                    </div>

                    <div className="">
                        <FullLogo />
                    </div>

                </div>
                :
                <MainWallOfLoveComponent />
            }

        </div>
    );

}